import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Lateral Band Holds 3x:30/side & 3×10/side Superman Extensions`}</p>
    <p>{`Sumo Deadlift High Pulls 5×4@ approx 75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`21-HSPU’s (RX+ 2″ deficit)`}</p>
    <p>{`15-Deadlifts (225/155)`}</p>
    <p>{`9-Box Jumps (24/20″)(RX+ 30/24″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      